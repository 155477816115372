exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-storyblok-entry-full-slug-tsx": () => import("./../../../src/pages/{storyblokEntry.full_slug}.tsx" /* webpackChunkName: "component---src-pages-storyblok-entry-full-slug-tsx" */),
  "component---src-pages-using-ssr-tsx": () => import("./../../../src/pages/using-ssr.tsx" /* webpackChunkName: "component---src-pages-using-ssr-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-tsx": () => import("./../../../src/templates/using-dsg.tsx" /* webpackChunkName: "component---src-templates-using-dsg-tsx" */)
}

